<template>
   <div class="mt-4">
      <div style="display: grid;">
         <div style="display: grid; grid-template-columns: 0fr 1fr; grid-gap: 20px;">
            <template v-if="image_name != null && image_name != ''">
               <img :src="image_name" alt="" style="width: 200px; height: 120px; border-radius: 6px" />
            </template>
            <template v-else>
               <img src="../../../../public/placeholder/placeholder-article.png" alt="" style="width: 200px; height: 120px; border-radius: 6px">
            </template>
            <div class="campaign_card_grid">
               <template v-if="title != null && title != ''">
                  <div class="mt-2 campaign_content_title">{{ title }}</div>
               </template>
               <template v-else>
                  <div class="mt-2 campaign_content_title">[ Title ]</div>
               </template>
               <div class="actiontype">
                  <span class="content_action_type" :style="{background: action_type_bg}">{{ action_type.replace(/_/, " ") }}</span>
               </div>
               <div class="campaign_content_date">
                  {{ created_at }}
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   props: ["title", "created_at", "image_name", "action_type", "action_type_bg"]
}

</script>