<template>
   <Layout>
      <div class="row" id="campaign">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="mb-4" style="display: grid; grid-template-columns: 1fr 50px; grid-gap: 20px;">
                     <div style="display: grid; grid-template-columns: 0fr 0.8fr;">
                        <router-link :to="{ name: 'campaign' }">
                           <h4 style="color: #00A3FF; cursor: pointer" class="mr-2 mb-0">Campaigns</h4>
                        </router-link>
                        <h4 style="color: #000; word-break: break-all;" class="mb-0"> / {{ form.name }} </h4>
                     </div>
                     <div class="page-title-box d-flex align-items-center justify-content-between pb-0">
                        <div class="page-title-right">
                           <router-link to="/campaign">
                              <span style="color: grey">Back</span>
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                     <div class="all-tabs ml-2 mt-3" style="gap: 20px">
                        <template v-if="can('overall-analytics')">
                           <span @click="checkTab('overall')" :class="this.can('overall-analytics') && this.view_tab == 'overall' ? 'tab-options' : 'campaign_tab'">Overall</span>
                        </template>
                        <template v-if="can('communication')">
                           <span @click="checkTab('touchpoint')" :class="this.can('communication') && this.view_tab == 'touchpoint' ? 'tab-options' : 'campaign_tab'">Touchpoints</span>
                        </template>
                        <template v-if="can('view-campaign-content')">
                           <span @click="checkTab('content')" :class="this.can('view-campaign-content') && this.view_tab == 'content' ? 'tab-options' : 'campaign_tab'">Content</span>
                        </template>
                     </div>
                  </div>
                  <template v-if="can('communication')">
                     <template v-if="isTouchpoint">
                        <touchpoint />
                     </template>
                  </template>
                  <template v-if="can('view-campaign-content')">
                     <template v-if="isCampaignContent">
                        <div class="campaign-content-tabs mt-4">
                           <div @click.prevent="fetchAllCampaign('all')" :class="campaign_active_tab == 'all' ? 'mx-2 opt-active' : 'mx-2 opt'">All</div>
                           <div @click.prevent="fetchAllCampaign('case')" :class="campaign_active_tab == 'case' ? 'mx-2 opt-active' : 'mx-2 opt'">Case</div>
                           <div @click.prevent="fetchAllCampaign('video')" :class="campaign_active_tab == 'video' ? 'mx-2 opt-active' : 'mx-2 opt'">Video</div>
                           <div @click.prevent="fetchAllCampaign('newsletter')" :class="campaign_active_tab == 'newsletter' ? 'mx-2 opt-active' : 'mx-2 opt'">Newsletter</div>
                           <div @click.prevent="fetchAllCampaign('newsarticle')" :class="campaign_active_tab == 'newsarticle' ? 'mx-2 opt-active' : 'mx-2 opt'">Article</div>
                           <div @click.prevent="fetchAllCampaign('live_event')" :class="campaign_active_tab == 'live_event' ? 'mx-2 opt-active' : 'mx-2 opt'">Live Event</div>
                        </div>
                        <div v-for="v in all_campaign_data.data" :key="v.id">
                           <template v-if="v.action_data != null && v.action_data != ''">
                              <campaign-content :created_at="v.created_at" :image_name="v.action_data.image_name" :title="v.action_data.title" :action_type="v.action_type" :action_type_bg="v.action_type == 'video' ? 'rgba(139, 92, 246, 1)' : v.action_type == 'case' ? 'rgba(245, 158, 11, 1)' : v.action_type == 'newsletter' ? 'rgba(236, 72, 153, 1)' : v.action_type == 'newsarticle' ? 'rgba(14, 165, 233, 1)' : v.action_type == 'live_event' ? 'rgba(22, 163, 74, 1)' : ''" />
                           </template>
                        </div>
                        <template v-if="all_campaign_data.total_pages > 1">
                           <div class="data_paginate mt-4 d-flex justify-content-end">
                              <div class="dataTables_paginate paging_simple_numbers">
                                 <ul class="pagination pagination-rounded mb-0">
                                    <b-pagination v-model="campaign_current_page" first-number last-number  :total-rows="all_campaign_data.total"
                                    :total-pages="all_campaign_data.total_pages" :per-page="all_campaign_data.per_page"></b-pagination>
                                 </ul>
                              </div>
                           </div>
                        </template>
                        <template v-if="noData">
                           <div class="no_content">
                              <div style="display: grid">
                                 <img src="../../../../public/noData.svg" alt="" />
                                 <span class="no_content_text">No Content Available</span>
                              </div>
                           </div>
                        </template>
                     </template>
                  </template>
                  <template v-if="can('overall-analytics')">
                     <template v-if="isOverall">
                        <template v-if="hasData">
                           <OverallAnalytics :src="getOverallAnalytics" />
                        </template>
                        <template v-if="noData">
                           <div class="no_content">
                              <div style="display: grid">
                                 <img src="../../../../public/noData.svg" alt="" />
                                 <span class="no_content_text">No Content Available</span>
                              </div>
                           </div>
                        </template>
                     </template>
                  </template>
                  <template v-if="!can('communication') && !can('view-campaign-content') && !can('overall-analytics')">
                     <div class="no_content">
                        <div style="display: grid; justify-items: center">
                           <img src="../../../../public/noData.svg" alt="" />
                           <span class="no_content_text">No Content Found</span>
                        </div>
                     </div>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import campaign from "../../../mixins/ModuleJs/campaign";
import touchpoint from "../campaign/touchpoint.vue";
import campaignContent from "../campaign/campaingContent.vue";
import MixinRequest from "../../../mixins/request";
import OverallAnalytics from "../campaign/overallAnalytics.vue";

export default {
   data() {
      return {
         campaignId: this.$route.params.id,
         activeTab: 'touchpoint',
      };
   },
   components: {
      Layout,
      touchpoint,
      campaignContent,
      OverallAnalytics
   },
   mixins: [campaign, MixinRequest],
   created() {
      this.fetchCampaign(this.$route.params.id);
   }
};
</script>

