<template>
      <div style="height: 200vh" class="mt-4">
         <iframe
            :src="src"
            frameborder="0"
            width="100%"
            height="100%"
            scrolling="no"
         ></iframe>
      </div>
 </template>
 
 <script>
 import MixinRequest from "../../../mixins/request";
 import campaignMixin from "../../../mixins/ModuleJs/campaign";
 
 export default {
   props: ["src"],
   mixins: [MixinRequest, campaignMixin],
 };
 </script>
 